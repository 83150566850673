import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class NavigationService {
  private history: string[] = [];
  private currentRouteSubject = new BehaviorSubject<any>(null);
  private _currentRoute$ = this.currentRouteSubject.asObservable();
  get currentRoute$(): Observable<any> {
    return this._currentRoute$;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRouteSubject.next(this.resolveRoute().snapshot.data);
        return this.history.push(event.urlAfterRedirects);
      });
  }

  private resolveRoute(route = this.activatedRoute): ActivatedRoute {
    return route.firstChild ? this.resolveRoute(route.firstChild) : route;
  }

  async back(fallback: string = '/') {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      await this.router.navigateByUrl(fallback);
    }
  }
}
